@import "theme-variable";

.bg-primary{
    background-color: $theme-primary-color !important;
}
.d-flex-container{
      
    display: flex;
}
.wrap{
    flex-wrap:wrap;
}
.justify{
&-center{
    justify-content: center;
}
&-between{
    justify-content: space-between;
}
&-evenly{
    justify-content: space-evenly;
}
&-start{
    justify-content: flex-start;
}
&-end{
    justify-content: flex-end;
}
}
.grid-10{
    grid-gap: 10px;
}

.align{
    &-center{
        align-items: center;
    }
    &-start{
        align-items: flex-start;
    }
}

.btn{
    padding: 10px 20px;
    border-radius: 10px;
    border: none;

    &-primary{
        background-color: $theme-primary-color !important;
        color: $theme-light-color;

       
    }
    &-dark{
        background-color: $theme-grey-color !important;
        color: $theme-light-color;
    }
    &-secondary{
        background-color: $theme-light-color;
        color: $theme-dark-color;
       
        &:focus{
            background-color: lighten($theme-dark-color, 30%);
        }
        &:disabled{
            background-color: lighten($theme-dark-color, 10%);
        }
    }
    &-login{
        padding: 10px 22px;
        font-size: 14px;
        letter-spacing: 1.3px;
        border-radius: 50px;
    }
@media (max-width: 991px) {
    padding: 5px 8px;
    font-size: .75rem;
}
}
.btn-primary{
    &:focus{
        background-color: $theme-primary-color !important;
    }
    &:disabled{
        background-color: lighten($theme-dark-color, 40%);
    }
}