

$tab-active: #300B78;

/* Override Bootstrap color Variables */
$primary: #5E2EBA;
$warning: #F2A818;
$danger: #DF3030;
$dark: #000000;
$light: #3D3D3D;
$white: #ffffff;
$lightgrey:#73839C;


@mixin card-shadow {
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(125, 125, 125, 0.1);
    border-radius: 10px;
  }
