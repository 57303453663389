/* You can add global styles to this file, and also import other style files */


/* Importing Bootstrap SCSS file. */

@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "reset";
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~intl-tel-input/build/css/intlTelInput.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "icons";
@import 'theme-variable';

/* Styles for fullscreen mode leaflet map */

@import url('leaflet.fullscreen/Control.FullScreen.css');

/* Styles for locatebutton leaflet map */

@import url('leaflet.locatecontrol/dist/L.Control.Locate.css');
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";


@font-face {
    font-family: "myFont-semibold";
    src: url("../assets/fonts/gilroy/Gilroy-SemiBold.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "myFont-medium";
    src: url("../assets/fonts/gilroy/Gilroy-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "myFont-regular";
    src: url("../assets/fonts/gilroy/Gilroy-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "myFont-bold";
    src: url("../assets/fonts/gilroy/Gilroy-Bold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

body {
    // font-family: 'Poppins', sans-serif;
    font-family: myFont-semibold;
    color: $light;
    font-size: 14px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
p,table,.table{
    font-family: myFont-medium;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: $dark;
}

h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

h4 {
    line-height: 35px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

.lead {
    font-weight: 400;
    margin-bottom: 25px;
}

.font-weight-100 {
    font-weight: 100;
}

.font-weight-200 {
    font-weight: 200;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.table th, .table td {
  padding: 0.75rem 1.2rem;
  @media  screen and (max-width:720px) {
    padding: .5rem;
  }
}

.table th {
  vertical-align: middle !important;;
}

footer {
    margin-top: auto;
}

main {
    margin: 0 auto;
    max-width: 80vh;
}


/* Form Over Ride */

.form-control {
    color: $dark;
    font-weight: 400;
    border: 1px solid #CFCFCF;
    font-size: 14px;
    height: 40px;
}

.form-control::placeholder {
    color: $lightgrey;
}

.form-row .invalid-tooltip {
    left: 0px !important;
}

.required {
    color: $danger;
}

select.form-control {
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    background-image: linear-gradient(45deg, transparent 50%, #C4C4C4 50%), linear-gradient(135deg, #C4C4C4 50%, transparent 50%);
    background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 8px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 7px 7px, 7px 7px, 1px 0em;
    background-repeat: no-repeat;
    padding-right: 30px;
}

select.form-control option {
    text-indent: 20px;
}

.search-wrap .dropdown-menu {
  font-size: 14px;
  width: 250px;

  .dropdown-item {
    padding: 8px 13px;
    &.active{
      background-color: $theme-primary-color !important;
    }
  }
}

.selectdroupdown input {
    background-image: linear-gradient(45deg, transparent 50%, #C4C4C4 50%), linear-gradient(135deg, #C4C4C4 50%, transparent 50%);
    background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 8px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 7px 7px, 7px 7px, 1px 0em;
    background-repeat: no-repeat;
}


/*
.form-check-inline {
  margin-right: 1.5rem;
}

.form-check-input {
  width : 20px;
  height: 20px;
}
*/

.form-group {
    margin-bottom: 1.5rem;
}

.form-row {
    margin-right: -10px;
    margin-left: -10px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.btn-lg,
.btn-group-lg>.btn {
    font-size: 1.10rem;
}

.btn-sm,
.btn-group-sm>.btn {
    padding-left: 1rem;
    padding-right: 1rem;
}

.alert-danger {
    margin-top: 10px;
    font-size: 11px;
    padding: 10px;
    font-weight: 300;
}


/* Start Model */

.model-main {
    position: relative;
    width: 950px;
}

.dialog-close {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 99;
}

.dialog-close i {
    color: #232930;
    font-size: 20px;
    font-weight: 400;
}

.mat-dialog-container {
    padding: 0 !important;
    border-radius: 0 !important;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.8);
}

.form-anchor {
    color: $theme-primary-color;
    cursor: pointer;
}

.form-anchor:hover {
    color: #da8f1e;
    text-decoration: underline;
}


/*Breadcrumb*/

.breadcrumb {
    background: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
    font-size: 12px;
}


/*Pagination styles*/

ul.pagination {
    margin-top: 10px;
    margin-bottom: 64px;
}

.page-link {
    border: 1px solid #DEE2E6;
    font-weight: normal;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #6C757D;
}

.page-link:hover,
.page-link:active,
.page-link:focus {
  box-shadow: unset;
  color: #fff;
  text-decoration: none;
  background: $theme-primary-color;
  border-color: $theme-primary-color;
}

.listsearch .container {
    padding: 0;
}

.rupes-icon {
    font-family: Arial, Helvetica, sans-serif;
}


/* Pay Now Button*/

.paynowbtn {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    i {
        font-size: 22px;
        line-height: 21px;
        &::before {
            font-size: 22px;
            line-height: 21px;
        }
    }
}

.title-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    h3 {
        margin-bottom: 0;
    }
    .head-right {
        display: flex;
        align-items: center;
    }
    button {
        margin-left: 30px;
    }
}

.print-icon {
  @media print {
    display: none;
  }
  font-size: 25px;
  color: #5E6871;

  button {
    color: #5E6871;
    background-color: #fff;
    border: none;
  }

  button:hover {
    color: $theme-primary-color;
  }

  &_header-table {
    button {
      background: #FBFBFB;
    }
  }
}

/* Table */

.table-hold {
    // @include card-shadow;
    background: #FFFFFF;
    // border: 1px solid #EFEFEF;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(125, 125, 125, 0.1);
    // border-radius: 10px;
    margin-bottom: 30px;
}

.table-hold table {
    margin: 0;
}

.th-bg th {
    background: #FBFBFB;
    font-size: 14px;
    font-weight: 500;
}

.carousel .carousel-indicators {
    bottom: -36px;
    margin: 0;
}

.carousel .carousel-indicators li {
    background-color: #394A64;
    border: none;
    height: 5px;
    width: 24px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    opacity: 1;
}

.carousel .carousel-indicators li.active,
.carousel .carousel-indicators li:hover {
    background-color: $theme-primary-color;
}

.carousel-control-prev,
.carousel-control-next {
    opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.93);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    &::before {
        font-family: bootstrap-icons !important;
        font-size: 28px;
        color: #6F43C4;
    }
}

.carousel-control-prev-icon::before {
    content: "\f12f";
}

.carousel-control-next-icon::before {
    content: "\f138";
}

.property-slider .carousel-inner {
    border-radius: 5px;
}

.btn-outline-secondary {
    border: 1px solid #CDCDCD;
    color: #394A64;
    font-size: 14px;
    i {
        margin-right: 5px;
    }
}

.btn-outline-secondary:hover {
    background: #5E2EBA;
    border: 1px solid #5E2EBA;
    color: #fff;
}

@media (max-width: 500px) {
    .carousel-indicators {
        display: none;
    }
}


/* Start Accordion */

.accordion .card {
    border: none;
    border-bottom: 1px solid #D4D7DA !important;
    border-radius: 0;
}

.accordion p {
    line-height: 31px;
    margin-bottom: 30px;
}

.accordion .card-header {
    background: none;
    padding: 0;
    border: none;
    button {
        display: block;
        padding: 0;
        width: 100%;
        text-align: left;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
}

.accordion .card h5 {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding: 18px 40px 18px 0px;
  margin: 0;

  &:hover {
    color: $theme-primary-color;
    text-decoration: none;

  }
}

.accordion {
  button::after {
    content: "\f2ea";
    font-family: bootstrap-icons !important;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 11px;
    color: $theme-primary-color;
  }

  button.collapsed::after {
    content: "\f4fe";
    font-family: bootstrap-icons !important;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 11px;
    color: $theme-primary-color;
  }

  button:focus, button:hover {
    box-shadow: none;
    background: none;
  }
  button.see_all{
    margin-top: 20px;
    &::after{
    display: none;
  }
  &:hover,&:focus{
    background-color: $theme-primary-light-color;
  }
}
}

.accordion .card-body {
    padding: 0;
}


/* End Accordion */

.fz-16 {
    font-size: 16px !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.height-container {
    min-height: calc(100vh - 116px - 52.6px - 45px - 69.5px);
}

ngb-datepicker {
  .ngb-dp-header {
    background-color: #f8f9fa !important;
  }

  .btn-light:focus, .btn-light:hover {
    background: #FFA726 !important;
  }

  .ngb-dp-weekdays {
    background-color: #f8f9fa !important;
  }

  .btn-light:active, .btn-light.active {
    background: #FFA726 !important;
  }

  .btn-light:focus-visible {
    border-color: #FFA726 !important;
  }

  .ngb-dp-weekday {
    color: #FFA726 !important;
  }

  .btn-light {
    color: #3C3C3C;
  }
}

ngb-typeahead-window {
    width: 100%;
}

.ng-dropdown-panel {
    font-size: 16px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-radius: 5px;
  color: #495057;
  background-color: #ffffff;
  border-color: $theme-primary-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(219 152 37 / 25%) !important;
}

.ng-dropdown-panel-items.scroll-host {
    padding: 10px 0;
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(44, 44, 44, 0.3);
        border-radius: 100px;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
}

.ng-option {
    padding: 4px 24px !important;
    border-radius: 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $theme-primary-color;
  color: #fff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: $theme-primary-color;
  color: #fff;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $theme-primary-color !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $theme-primary-color !important;
}

.modal-content {
    max-height: calc(100% - 80px)!important;
}

@media only screen and (max-width: 768px) {
    .mb-4 {
        margin-bottom: 0;
    }
    .mmb-4 {
        margin-bottom: 1.5rem!important;
    }
}

i.fa-close.fa-times {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
}


/* Begin Responsive Table */

@media screen and (max-width:1000px){
  // app-main{
  //   display: block;
  //   overflow: hidden;
  // }
  .announcements{
    line-height: 1.2 !important;
  }
  .property-details{
    th,td{
      padding: .5rem;
    }
  }
  .tax-due-table{
    table{
      td{
        padding: 1rem 1.2rem;
      }
    }
  }
  .responsive-flex-table{
      display: flex;
      margin-bottom: 0px;
      thead{
          display: inline-flex;
          flex-direction: column;
          min-width: 40%;
          background-color: #fff;
          z-index: 99;
        
      }
      tbody {
          overflow: auto;
          flex-wrap: nowrap;
          display: flex;
          width: 100% !important;
          tr {
              display: inline-flex;
              flex-direction: column;
              width: 100% !important;
              td{
                  white-space: nowrap;
              }
          }
      }
      th,td{
          width: 100% !important;
          white-space: nowrap;
          padding-left: 1rem !important;
          padding-top: .5rem !important;
          padding-bottom: .5rem !important;
          border-bottom: none !important;
          border-right: 1px solid #dee2e6;
          min-height: 60px;
      }
  }
  .mob_align_right{
    justify-content: flex-end !important;
  }

  }

.no_data {
    font-size: 15px;
    font-weight: 600;
    color: #6c757d;
}


/* End Responsive Table */


/* Mapview legends color block */

.color-block {
    width: 60px;
    text-align: center;
    height: 20px;
}

.owl-nav {
    position: absolute;
    top: -40px;
    right: 10px;
    opacity: 1;
    margin-top: 0px !important;
   
    @media screen and (max-width: 768px) {
        top: -35px;
        right: 8px;
    }
}
.owl-theme .owl-nav [class*=owl-]{
    background-color: transparent !important;
    &:hover{
        background-color:  transparent !important;
    }
}

.owl-next{
    background-image: url('../assets/images/black_side_arrow_right.png') !important;
    background-repeat: no-repeat !important;
}
.owl-prev{
    background-image: url('../assets/images/black_side_arrow.png') !important;
    background-repeat: no-repeat !important;
}
.owl-theme .owl-nav [class*=owl-] {
    padding: 10px 10px !important;
}

.owl-item {
    padding: 10px;
}

ngb-accordion {
    .card {
        background-color: transparent;
        .card-body {
            padding-bottom: 20px;
            color: rgb(35 41 48 / 60%);
        }
    }
}

@media screen and (max-width:768px) {
    .mb-120 {
        margin-bottom: 20px !important;
    }
    .dialog-close {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}

.listsearch p.advanced_search {
    margin: 0px;
    color: #50585F;
    bottom: 40px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #ffffff;
    background-color: $theme-primary-light-color;
    border-color: $theme-primary-light-color;
}
a{
  color: $theme-primary-color;
  &:hover{
    color: $theme-primary-color;
  }
}
.text-primary{
  color: $theme-primary-color !important;
}
.mobile{
  &-hide{
    display: flex !important;
  }
  &-show{
    display: none !important;
  }
  
}

@media screen and (max-width:768px) {
  .mb-120{
    margin-bottom: 20px !important;
  }
  .mobile{
    &-hide{
      display: none !important;
    }
    &-show{
      display: flex !important;
    }
  }
}

@media screen and (max-width:992px) {
    .responsive-flex-table{
        display: flex;
        thead {
            display: inline-flex;
            flex-direction: column;
            // min-width: 40%;
            background-color: #fff;
            z-index: 99;
           
        }
        tbody {
            overflow: auto;
            flex-wrap: nowrap;
            display: flex;
            width: 100% !important;
            tr {
                display: inline-flex;
                flex-direction: column;
                width: 100% !important;
                td{
                    white-space: nowrap;
                }
            }
        }
        th,td{
            width: 100% !important;
            white-space: nowrap;
            padding: .5rem !important;
            border-bottom: none !important;
            min-height: 38px;
        }
    } 
}
input{
    &:focus{
        border-color: $theme-primary-color !important;
        box-shadow: 0 0 0 0.2rem rgb(219 152 37 / 25%) !important;
    }
}
input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 0px rgba(0,0,0,0) inset;
    transition: background-color 5000s ease-in-out 0s;
    
}
input:-webkit-autofill:hover{
    -webkit-box-shadow: 0 0 0px 0px rgba(0,0,0,0) inset;
    transition: background-color 5000s ease-in-out 0s;
}
input:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0px 0px rgba(0,0,0,0) inset;
    transition: background-color 5000s ease-in-out 0s;
}
@media screen and (max-width:992px) {
    .map-page{
      flex-direction: column !important;
      height: auto !important;
    }
    app-sidebar{
        order: 2;
    }
    .map-wrapper{
        height: calc(50vh - 69.5px) !important;
    
      }
  }
  .ng-select-container {
    input{
        &:focus{
            box-shadow: none !important;
            border: none !important;
        }
    }
  }

  /* Maps label css */

  #map {
    .map-label {
      background: none;
      border: none;
      font-size: 14px !important;
      font-weight: 600;
      color: #fff;
      box-shadow: none;
      text-shadow: -2px 1px 3px #111;
    
      &::before, &::after {
        content: none;
      }
    }
    .map-landmark-label {
      background: none;
      border: none;
      font-size: 14px !important;
      font-weight: 600;
      color: #ffe27e;
      box-shadow: none;
      text-shadow: -2px 1px 3px #111;
      &::before, &::after {
        content: none;
      }
    }
  }
  
  .btn{
    &:focus{
        box-shadow: none !important;
    }
  }

  .financial-year {
    .ng-select.ng-select-single .ng-select-container {
        height: 40px;
    }
  }

  .form-required {
    &::after {
        content: ' *';
        color: #DF3030;
    }
  }

 .tab-settt{
        .nav-tabs{
            .nav-link{
                font-size: 18px;
        letter-spacing: 1px;
            }
        }
  }

  section.faq{
    .listsearch{
        margin-bottom: 40px;
        display: block;
    }
    
    .img-box{
        text-align: center;
        margin: 30px auto;
    }
    h2.brd-all {
        font-size: 20px !important;
        border: solid 2px;
        display: inline-block;
        padding: 5px 15px;
        border-radius: 4px;
    }
    h4.faq-hd.internal-qs~p {
        margin-left: 30px;
    }
    h4.faq-hd.internal-qs {
        padding: 0px;
        margin-left: 20px;
        font-size: 14px;
    }
    span.ans-internal {
        display: inline-block;
        font-style: italic;
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
    }
    ol p.d-inline-block {
        margin-left: 20px;
        font-size: 14px !important;
    }
    span.brd-bdm {
        border-bottom: solid 1px;
    }
  }
  .search-wrap {
    @include card-shadow;
    padding: 25px 25px 25px 25px;
  }
  .landmarks__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
  }
  
  .patta-colony-details{
    width: 250px;
    position: absolute;
    border-radius: 10px;
    background: #efefef;
    padding: 10px;
  }

  .patta-card-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
}

.isInvalid {
    border: red 1px solid;
    border-radius: 5px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}


.ng-select {
    &.isInvalid {
        box-shadow: none;
        border: none;
        .ng-select-container {
            border: red 1px solid !important;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8 !important;
        }
    }
}

.image-uploader {
    .browse-btn {
        color: #fff !important;
    }
    
    .image-thumbnail {
        height: 30px;
        width: 40px;
        margin-left: 20px;
        margin-bottom: 7px;
    }
    
    .remove-btn {
        position: absolute;
        top: -5px;
        right: -5px;
    }
}

.required-field {
    &:after {
        content: " * ";
        color: #DF3030 !important;
    }
}

.page-title-box{
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    h4{
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px !important;
        min-height: 36px;
        display: flex;
        align-items: center;
    }
}
