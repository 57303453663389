$theme-primary-color:#F37516;
$theme-primary-light-color:#FFFFFF;
$theme-secondary-color:#FFFFFF;
$theme-navigation-text-color:#F37516;
$theme-dark-color:#222931;
$theme-light-color:#FFFFFF;
$theme-cyan-color:#1AB1B0;
$theme-green-color:#53B96A;
$theme-blue-color:#5774E3;
$theme-pink-color:#F95B7E;
$theme-red-color:#E55959;
$theme-orange-color:#FE8550;
$theme-grey-color:#F37516;




.bg-primary{
    background-color: $theme-primary-color !important;
}
.d-flex-container{
      
    display: flex;
}
.wrap{
    flex-wrap:wrap;
}
.justify{
&-center{
    justify-content: center;
}
&-between{
    justify-content: space-between;
}
&-evenly{
    justify-content: space-evenly;
}
&-start{
    justify-content: flex-start;
}
&-end{
    justify-content: flex-end;
}
}
.grid-10{
    grid-gap: 10px;
}

.align{
    &-center{
        align-items: center;
    }
    &-start{
        align-items: flex-start;
    }
}

.btn{
    padding: 10px 20px;
    border-radius: 10px;
    border: none;

    &-primary{
        background-color: $theme-primary-color !important;
        color: $theme-light-color;

       
    }
    &-dark{
        background-color: $theme-grey-color !important;
        color: $theme-light-color;
    }
    &-secondary{
        background-color: $theme-light-color;
        color: $theme-dark-color;
       
        &:focus{
            background-color: lighten($theme-dark-color, 30%);
        }
        &:disabled{
            background-color: lighten($theme-dark-color, 10%);
        }
    }
    &-login{
        padding: 10px 22px;
        font-size: 14px;
        letter-spacing: 1.3px;
        border-radius: 50px;
    }
}
.btn-primary{
    &:focus{
        background-color: $theme-primary-light-color !important;
    }
    &:disabled{
        background-color: lighten($theme-dark-color, 40%);
    }
}